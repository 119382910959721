import React from "react";
import {useTranslation} from "react-i18next";
import KeyValueTable from '../../components/KeyValueTable/KeyValueTable';
import KeyValueText from "../KeyValueText/KeyValueText";
import { accessories } from '../../../utils/accessories';
import { formatPrice } from '../../../utils/price';

interface props {
    objectData: any
    configurationData: any
    showTable?: boolean
    tableStyle?: number
    className?: string
}

interface Fee {
    key: string;
    name: string;
    value: number;
}

const CostData: React.FC<props> = ({objectData, configurationData, showTable = true, tableStyle = 1, className = ''}) => {
    const { t } = useTranslation('onboarding');

    const productMonth = objectData.fees.find((fee: Fee) => fee.key === "productMonth");
    const productkwh = objectData.fees.find((fee: Fee) => fee.key === "productkwh"); // incl. in tariff
    const activationFee = objectData.fees.find((fee: Fee) => fee.key === "activation");
    const tariffPrice = objectData.tariffs.priceElements?.[0]?.price;

    let installFee: Fee | undefined = undefined;

    if(objectData.investmentOption === 'User buy/rent') {
        if (configurationData.extensionState === 'B' || configurationData.extensionState === 'C1') {
            installFee = objectData.fees.find((fee: Fee) => fee.key === "installC1");
        } else if (configurationData.extensionState === 'C2') {
            installFee = objectData.fees.find((fee: Fee) => fee.key === "installC2");
        }
    }

    /* Calculate costs */

    let initialCosts = 0;
    let monthlyCosts = 0;
    let kWhCosts = 0;

    // Object Data
    initialCosts += activationFee?.value ?? 0;
    monthlyCosts += productMonth?.value ?? 0;
    kWhCosts += tariffPrice ?? 0;

    // Configuration Data
    initialCosts += installFee?.value ?? 0;
    if (configurationData.investOption === 'buy') {
        initialCosts += parseFloat(configurationData.investPrice) || 0;
    }
    if (configurationData.investOption === 'rent') {
        monthlyCosts += parseFloat(configurationData.investPrice) || 0;
    }

    // Accessories Data
    accessories.forEach(accessory => {
        if (configurationData[accessory.field] === 'true') {
            initialCosts += accessory.price ?? 0;
        }
    });

    const costArr = {
        [t('confirmation.costs.initial')]: formatPrice(initialCosts),
        [t('confirmation.costs.monthly')]: formatPrice(monthlyCosts),
        [t('confirmation.costs.kwh')]: formatPrice(kWhCosts)
    };

    return (
        <>
            {showTable ? (
                <KeyValueTable className={className} title={t('confirmation.costs.lead')} data={costArr}
                               style={tableStyle} priceTable={true}/>
            ) : (
                <KeyValueText className={className} title={t('confirmation.costs.lead')} data={costArr}/>
            )}
        </>
    );
}

export default CostData;