import React, {FormEvent, useContext, useState, useEffect} from "react";
import { produce } from 'immer';
import {Trans, useTranslation} from "react-i18next";
import LayoutBox from "../../../components/Box/LayoutBox";
import Input from "../../../components/Input/Input";
import ErrorMessages from "../../../components/ErrorMessage/ErrorMessages";
import Message from "../../../components/Message/Message";
import Button from "../../../components/Button/Button";
import useFormErrors from "../../../../utils/FormErrors";
import {FormStateContext} from "../../../context/FormStateContext";
import useFormData from "../../../../utils/FormData";
import { isEmail } from "../../../../utils/validation";
import { API_URL } from "../../../../utils/constants";
import OrderBox from "../../../components/OrderBox/OrderBox";

interface props {
    onNext: () => void;
    onPrev: () => void;
}

const AccountForm: React.FC<props> = ({ onNext, onPrev }) => {
    const { t, i18n } = useTranslation('onboarding');
    const getLanguage = () => i18n.language.substring(0, 2);

    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState('account');

    const {errors, setError, resetErrors, isValid} = useFormErrors();

    const {form, setForm} = useContext(FormStateContext);

    const {formData, setDataField} = useFormData(form.steps.account.value);

    const onChange = (inputName: string, newValue: string) => {
        setDataField(inputName, newValue);
    };

    const objectData = form.steps.object.data;

    // Check activation
    useEffect(() => {
        let intervalId: NodeJS.Timer;

        if (step === 'activation') {
            const checkActivation = async () => {
                const credentials = {
                    email: formData.email.trim() ?? '',
                    password: formData.password ?? ''
                };

                try {
                    let requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(credentials)
                    };
                    const responseLogin = await fetch(`${API_URL}/api/auth/login`, requestOptions);
                    if (responseLogin.ok) {
                        const dataLogin = await responseLogin.json();
                        // Check if login was successful
                        if (dataLogin.success && dataLogin.token) {
                            let requestOptions = {
                                method: 'GET',
                                headers: {
                                    'Authorization': dataLogin.token
                                }
                            };
                            const responseUser = await fetch(`${API_URL}/api/user/info`, requestOptions);
                            const dataUser = await responseUser.json();
                            setStep('login');

                            setForm(
                                produce((formState) => {
                                    formState.steps.account = {
                                        ...formState.steps.account,
                                        valid: true,
                                        value: {
                                            email: formData.email.trim(),
                                            method: 'register',
                                            language: getLanguage()
                                        },
                                        data: dataUser.data
                                    };
                                })
                            );
                        }
                    }
                } catch (error) {
                    console.error('Check Activation Error:', error);
                }
            };

            // Fetch data initially
            checkActivation();

            // Fetch data every 30 seconds
            intervalId = setInterval(checkActivation, 10000);
        }

        // Clear interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [step]);

    const resetAccount = () => {
        setStep('login');
        setForm(
            produce((formState) => {
                formState.steps.account = {
                    ...formState.steps.account,
                    valid: false,
                    value: {
                        email: '',
                        method: '',
                        language: ''
                    }
                };
            })
        );
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (step === 'login') {
            await handleLogin();
        } else if (step === 'register') {
            await handleRegister();
        } else if (step === 'resetPW') {
            await handleResetPW();
        } else if (step === 'changePW') {
            await handleChangePW();
        } else if (step === 'activation') {
            await handleActivation();
        }
    };

    const handleLogin = async () => {
        /* Start - Validation */
        resetErrors();
        // Email
        if (!formData.email) {
            setError('email', t('account.email.errors.empty'));
        } else if (!isEmail(formData.email.trim())) {
            setError('email', t('account.email.errors.invalid'));
        }

        // Password
        !formData.password && setError('password', t('account.password.errors.empty'));

        /* End - Validation */

        if (isValid()) {
            setLoading(true);

            const credentials = {
                email: formData.email.trim() ?? '',
                password: formData.password ?? ''
            };

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                };
                const responseLogin = await fetch(`${API_URL}/api/auth/login`, requestOptions);
                if (!responseLogin.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataLogin = await responseLogin.json();
                    // Check if login was successful
                    if (dataLogin.success && dataLogin.token) {
                        let requestOptions = {
                            method: 'GET',
                            headers: {
                                'Authorization': dataLogin.token
                            }
                        };
                        const responseUser = await fetch(`${API_URL}/api/user/info`, requestOptions);
                        const dataUser = await responseUser.json();

                        setForm(
                            produce((formState) => {
                                formState.steps.account = {
                                    ...formState.steps.account,
                                    valid: true,
                                    value: {
                                        email: formData.email.trim(),
                                        method: 'login',
                                        language: getLanguage()
                                    },
                                    data: dataUser.data,
                                    billingAddress: {
                                        firstname: dataUser.data.firstName ?? '',
                                        lastname: dataUser.data.name ?? '',
                                        street: dataUser.data.streetAddress ?? '',
                                        city: dataUser.data.city ?? '',
                                        postalCode: dataUser.data.postalCode ?? '',
                                        country: dataUser.data.country ?? '',
                                        phone: dataUser.data.phone ?? '',
                                    }
                                };
                            })
                        );
                    } else {
                        switch (dataLogin.error) {
                            case 'login failed':
                                setError('email', t('account.login.errors.invalid'));
                                break;
                            case 'login not activated yet':
                                setError('email', t('account.login.errors.notActivated'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Login error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }

        }
    };

    const handleRegister = async () => {
        resetErrors();

        /* Start - Validation */
        // Email
        if (!formData.email) {
            setError('email', t('account.email.errors.empty'));
        } else if (!isEmail(formData.email.trim())) {
            setError('email', t('account.email.errors.invalid'));
        }

        // Password
        if (!formData.password) {
            setError('password', t('account.password.errors.empty'))
        } else if (formData.password.length < 8) {
            setError('password', t('account.password.errors.short'))
        }

        // Confirm Password
        if (!formData.confirmPassword) {
            setError('confirmPassword', t('account.confirmPassword.errors.empty'))
        } else if (formData.password !== formData.confirmPassword) {
            setError('confirmPassword', t('account.confirmPassword.errors.same'))
        }
        /* End - Validation */

        if (isValid()) {
            setLoading(true);


            const credentials = {
                email: formData.email.trim() ?? '',
                password: formData.password ?? '',
                language: getLanguage()
            };

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                };
                const responseRegister = await fetch(`${API_URL}/api/register`, requestOptions);
                if (!responseRegister.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataRegister = await responseRegister.json();
                    // Check if register was successful
                    if (dataRegister.success) {
                        setStep('activation');
                    } else {
                        switch (dataRegister.error) {
                            case 'Email already registered':
                                setError('email', t('account.email.errors.exists'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Register error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        }
    };

    const handleActivation = async () => {
        resetErrors();

        /* Start - Validation */
        // Token
        !formData.token && setError('token', t('account.token.errors.empty'));
        /* End - Validation */

        if (isValid()) {
            setLoading(true);

            const credentials = {
                email: formData.email ?? '',
                token: formData.token ?? '',
            };

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                };
                const responseActivate = await fetch(`${API_URL}/api/activate`, requestOptions);
                if (!responseActivate.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataActivate = await responseActivate.json();
                    // Check if activation was successful
                    if (dataActivate.success) {
                        setForm(
                            produce((formState) => {
                                formState.steps.account = {
                                    ...formState.steps.account,
                                    valid: true,
                                    value: {
                                        email: formData.email.trim(),
                                        method: 'register',
                                        language: getLanguage()
                                    }
                                };
                            })
                        );
                    } else {
                        switch (dataActivate.error) {
                            case 'Invalid Token':
                                setError('token', t('account.token.errors.invalid'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Activation error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        }
    };

    const handleResetPW = async () => {
        resetErrors();

        /* Start - Validation */

        // Email
        if (!formData.email) {
            setError('email', t('account.email.errors.empty'));
        } else if (!isEmail(formData.email.trim())) {
            setError('email', t('account.email.errors.invalid'));
        }
        /* End - Validation */

        if (isValid()) {
            setLoading(true);


            const credentials = {
                action: 'passwordResetStart',
                email: formData.email.trim() ?? '',
                language: getLanguage()
            };

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                };
                const responseResetPW = await fetch(`${API_URL}/api/auth/password`, requestOptions);
                if (!responseResetPW.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataResetPW = await responseResetPW.json();
                    // Check if reset password was successful
                    if (dataResetPW.success) {
                        setStep('changePW');
                    } else {
                        switch (dataResetPW.error) {
                            case 'Could not find email address':
                                setError('email', t('account.email.errors.notFound'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Reset Password error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        }
    };

    const handleChangePW = async () => {
        resetErrors();


        /* Start - Validation */
        // Token
        !formData.token && setError('token', t('account.token.errors.empty'));

        // Password
        if (!formData.newPassword) {
            setError('newPassword', t('account.password.errors.empty'))
        } else if (formData.newPassword.length < 8) {
            setError('newPassword', t('account.password.errors.short'))
        }

        // Confirm Password
        if (!formData.confirmNewPassword) {
            setError('confirmNewPassword', t('account.confirmPassword.errors.empty'))
        } else if (formData.newPassword !== formData.confirmNewPassword) {
            setError('confirmNewPassword', t('account.confirmPassword.errors.same'))
        }
        /* End - Validation */

        if (isValid()) {
            setLoading(true);


            const credentials = {
                action: 'setPassword',
                password: formData.newPassword ?? '',
                token: formData.token ?? '',
            };

            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                };
                const responseSetPW = await fetch(`${API_URL}/api/auth/password`, requestOptions);
                if (!responseSetPW.ok) {
                    setError('general', t('general.error'));
                } else {
                    const dataSetPW = await responseSetPW.json();
                    // Check if change password was successful
                    if (dataSetPW.success) {
                        setStep('changePWSuccess');
                    } else {
                        switch (dataSetPW.error) {
                            case 'Invalid Token':
                                setError('token', t('account.token.errors.invalid'));
                                break;
                            default:
                                setError('general', t('general.error'));
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Change Password error:', error);
                setError('general', t('general.error'));
            } finally {
                setLoading(false);
            }
        }
    };


    if (!form.steps.account.valid) {
        return (
            <div className='flex gap-x-md flex-col lg:flex-row'>
                <div className='w-full lg:w-7/12'>
                    {step === 'account' &&
                        <LayoutBox heading={t('account.lead')}>
                            <div className="w-full max-w-500 text-center">
                                <div className='mb-md'>{t('account.text')}</div>
                                <div className='flex gap-xs sm:gap-sm md:gap-md mb-md justify-center'>
                                    <Button
                                        text={t('general.yes')}
                                        small={true}
                                        onClick={() => setStep('login')}
                                    />
                                    <Button
                                        text={t('general.no')}
                                        small={true}
                                        onClick={() => setStep('register')}
                                    />
                                </div>
                            </div>
                        </LayoutBox>
                    }
                    {step === 'login' &&
                        <LayoutBox heading={t('account.login.lead')}>
                            <form onSubmit={onSubmit} className='w-full max-w-500 text-center'>
                                <ErrorMessages errors={errors}/>
                                <div className='mb-sm'>{t('account.login.text')}</div>
                                <Input
                                    name='email'
                                    placeholder={t('account.email.label')}
                                    value={formData.email}
                                    error={!!errors.email}
                                    type='text'
                                    onChange={onChange}
                                />
                                <Input
                                    name='password'
                                    placeholder={t('account.password.label')}
                                    value={formData.password}
                                    error={!!errors.password}
                                    type='password'
                                    onChange={onChange}
                                />
                                <div className='mb-sm'>
                                    <a onClick={() => {
                                        setStep('resetPW');
                                        resetErrors();
                                    }}>{t('account.login.forgotPW')}</a>
                                </div>
                                <Button
                                    text={t('account.login.button')}
                                    loading={loading}
                                    type='submit'
                                />
                                <div className='mt-sm'>{t('account.noAccount.text')}{' '}<a onClick={() => {
                                    setStep('register');
                                    resetErrors();
                                }}>{t('account.noAccount.link')}</a></div>
                            </form>
                        </LayoutBox>
                    }
                    {step === 'resetPW' &&
                        <LayoutBox heading={t('account.resetPW.lead')}>
                            <form onSubmit={onSubmit} className='w-full max-w-500 text-center'>
                                <ErrorMessages errors={errors}/>
                                <div className='mb-sm'>{t('account.resetPW.text1')}</div>
                                <Input
                                    name='email'
                                    placeholder={t('account.email.label')}
                                    value={formData.email}
                                    error={!!errors.email}
                                    type='text'
                                    onChange={onChange}
                                />
                                <Button
                                    text={t('account.resetPW.button')}
                                    loading={loading}
                                    type='submit'
                                />
                                <div className='mt-sm'><a onClick={() => {
                                    setStep('login');
                                    resetErrors();
                                }}>{t('account.backToLogin')}</a></div>
                            </form>
                        </LayoutBox>
                    }
                    {step === 'changePW' &&
                        <LayoutBox heading={t('account.resetPW.lead')}>
                            <form onSubmit={onSubmit} className='w-full max-w-500 text-center'>
                                <ErrorMessages errors={errors}/>
                                <div className='mb-sm'>{t('account.resetPW.text2')}</div>
                                <Input
                                    name='token'
                                    placeholder={t('account.token.label')}
                                    value={formData.token ?? ''}
                                    error={!!errors.token}
                                    type='text'
                                    autocomplete={'off'}
                                    onChange={onChange}
                                />
                                <Input
                                    name='newPassword'
                                    placeholder={t('account.newPassword.label')}
                                    value={formData.newPassword}
                                    error={!!errors.newPassword}
                                    type='password'
                                    autocomplete={'new-password'}
                                    onChange={onChange}
                                />
                                <Input
                                    name='confirmNewPassword'
                                    placeholder={t('account.confirmPassword.label')}
                                    value={formData.confirmNewPassword}
                                    error={!!errors.confirmNewPassword}
                                    type='password'
                                    autocomplete={'new-password'}
                                    onChange={onChange}
                                />
                                <Button
                                    text={t('account.resetPW.button')}
                                    loading={loading}
                                    type='submit'
                                />
                                <div className='mt-sm'><a onClick={() => {
                                    setStep('login');
                                    resetErrors();
                                }}>{t('account.backToLogin')}</a></div>
                            </form>
                        </LayoutBox>
                    }
                    {step === 'changePWSuccess' &&
                        <LayoutBox heading={'Passwort #'}>
                            <div className='w-full max-w-500 text-center'>
                                <Message type={'success'}>{t('account.resetPW.success')}</Message>
                                <Button
                                    text={t('account.backToLogin')}
                                    type='submit'
                                    onClick={resetAccount}
                                />
                            </div>
                        </LayoutBox>
                    }
                    {step === 'register' &&
                        <LayoutBox heading={t('account.register.lead')}>
                            <form onSubmit={onSubmit} className='w-full max-w-500 text-center'>
                                <ErrorMessages errors={errors}/>
                                <div className='mb-sm'>{t('account.register.text')}</div>
                                <Input
                                    name='email'
                                    placeholder={t('account.email.label')}
                                    value={formData.email}
                                    error={!!errors.email}
                                    type='text'
                                    onChange={onChange}
                                />
                                <Input
                                    name='password'
                                    placeholder={t('account.password.label')}
                                    value={formData.password}
                                    error={!!errors.password}
                                    type='password'
                                    onChange={onChange}
                                />
                                <Input
                                    name='confirmPassword'
                                    placeholder={t('account.confirmPassword.label')}
                                    value={formData.confirmPassword}
                                    error={!!errors.confirmPassword}
                                    type='password'
                                    onChange={onChange}
                                />
                                <Button
                                    text={t('account.register.button')}
                                    loading={loading}
                                    type='submit'
                                />
                                <div className='mt-sm'>{t('account.hasAccount.text') + ' '}<a onClick={() => {
                                    setStep('login');
                                    resetErrors();
                                }}>{t('account.hasAccount.link')}</a></div>
                            </form>
                        </LayoutBox>
                    }
                    {step === 'activation' &&
                        <LayoutBox heading={t('account.activation.lead')}>
                            <form onSubmit={onSubmit} className='w-full max-w-500 text-center'>
                                <Message type={'success'}>
                                    <Trans i18nKey="account.register.success" t={t}
                                           components={[<br/>]}/>
                                </Message>
                                <ErrorMessages errors={errors}/>
                                <div
                                    className='mb-sm'>{t('account.activation.text')}</div>
                                <Input
                                    name='token'
                                    placeholder={t('account.token.label')}
                                    value={formData.token ?? ''}
                                    error={!!errors.token}
                                    type='text'
                                    autocomplete={'off'}
                                    onChange={onChange}
                                />
                                <Button
                                    text={t('general.confirm')}
                                    loading={loading}
                                    type='submit'
                                />
                                <div className='mt-sm'><a onClick={() => {
                                    setStep('login');
                                    resetErrors();
                                }}>{t('account.backToLogin')}</a></div>
                            </form>
                        </LayoutBox>
                    }
                </div>
                <div className='w-full lg:w-5/12'>
                    <OrderBox objectData={objectData} configurationData={form.steps.configuration.value}/>
                </div>
            </div>
        );
    } else {
        return(
            <div className='flex gap-x-md flex-col lg:flex-row'>
                <div className='w-full lg:w-7/12'>
                    <LayoutBox heading={t('account.lead')}>
                        <div className='w-full max-w-500'>
                            <Message type={'success'}>
                                {form.steps.account.value.method === 'register' ?
                                    t('account.activation.success') : t('account.login.success')}
                            </Message>
                            <div className='flex gap-xs sm:gap-sm md:gap-md mb-md justify-center'>
                                <Button
                                    text={t('general.back')}
                                    small={true}
                                    type='submit'
                                    onClick={resetAccount}
                                />
                                <Button
                                    text={t('general.continue')}
                                    small={true}
                                    type='submit'
                                    onClick={onNext}
                                />
                            </div>
                        </div>
                    </LayoutBox>
                </div>
                <div className='w-full lg:w-5/12'>
                    <OrderBox objectData={objectData} configurationData={form.steps.configuration.value}/>
                </div>
            </div>
        );
    }
}

export default AccountForm;