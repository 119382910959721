import React, { ReactPortal } from 'react';
import classNames from "classnames";

interface props {
    data: { [key: string]: any }
    title?: string
    style?: number
    priceTable?: boolean
    className?: string
}

const KeyValueTable: React.FC<props> = ({ data, title, style = 1, priceTable = false, className = '' }) => {

    return (
        <div className={classNames({
                ['KeyValueTable w-full max-w-500 rounded-lg overflow-hidden ' + className]: true,
                'shadow-lg': style === 1
            })}>
            {title && <div className="text-white font-bold px-sm py-xs bg-primary">{title}</div>}

            <div>
                {Object.entries(data).map(([key, value]) => (
                    (value !== null && !Array.isArray(value)) && (
                        <div
                            className={classNames({
                                'flex text-left ': true,
                                'uneven:bg-white even:bg-lightGrey': style === 1,
                                'bg-card border-b-2 border-white last:border-none': style === 2
                            })} key={key}>
                            <div className={'flex align-middle px-xs md:px-sm py-xs ' + (priceTable ? 'w-8/12' : 'w-6/12')}>
                                <b>{key}</b></div>
                            <div
                                className={'flex align-middle px-xs md:px-sm py-xs justify-end text-right ' + (priceTable ? 'w-4/12' : 'w-6/12')}>{value && value}</div>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

export default KeyValueTable;