import React from "react";
import {formatPrice} from "../../../utils/price";
import {useTranslation} from "react-i18next";
import KeyValueTable from '../../components/KeyValueTable/KeyValueTable';
import KeyValueText from "../KeyValueText/KeyValueText";
import {getDayById} from "../../../utils/days";

interface props {
    objectData: any
    showTable?: boolean
    tableStyle?: number
    className?: string
}

interface Fee {
    key: string;
    name: string;
    value: number;
}

interface CustomInterval {
    price: number;
    startDay: number;
    endDay: number;
    startTime: string;
    endTime: string;
}

interface PriceElement {
    priceElementId: number;
    priceUnit: string;
    price: number;
    customIntervals?: CustomInterval[];
}

interface Tariff {
    currency: string;
    priceElements: PriceElement[];
}

const ObjectData: React.FC<props> = ({objectData, showTable = true, tableStyle = 1, className = ''}) => {
    const { t } = useTranslation('onboarding');

    const productMonth = objectData.fees.find((fee: Fee) => fee.key === "productMonth");
    const productkwh = objectData.fees.find((fee: Fee) => fee.key === "productkwh");
    const activationFee = objectData.fees.find((fee: Fee) => fee.key === "activation");
    const tariff: Tariff = objectData.tariffs;

    const productFee = (
        <div className='flex flex-col gap-y-0.5'>
            {productMonth?.value != null && (
                <div>
                    {formatPrice(productMonth.value) + t('general.perMonth')}
                </div>
            )}
            {productkwh?.value != null && (
                <div>
                    {formatPrice(productkwh.value) + t('general.perkWh')}<br/>
                    {productkwh.value > 0 && <div className='text-sm'>{t('object.tariffIncl')}</div>}
                </div>
            )}
            {productMonth?.value == null && productkwh?.value == null && '-'}
        </div>
    );

    const objectArr = {
        // [t('object.objectname')]: objectData.name ?? '-',
        [t('object.mygridProduct')]: objectData.product?.name ?? '-',
        [t('object.productFee')]: productFee,
        // [t('object.chargingTariff')]: objectData.tariff?.price != null ? formatPrice(objectData.tariff.price) + t('general.perkWh') : '-',
        [t('object.activationFee')]: activationFee?.value != null ? formatPrice(activationFee.value) : '-',
    };

    const tariffArr = {
        [t('general.regularPrice')]: tariff.priceElements[0].price ? formatPrice(tariff.priceElements[0].price) + tariff.priceElements[0].priceUnit : '-',

        ...(tariff.priceElements[0].customIntervals?.length && Object.fromEntries(
            tariff.priceElements[0].customIntervals.map(interval => {
                const startDay = getDayById(interval.startDay) ? t('general.daysShort.' + getDayById(interval.startDay)) : '';
                const endDay = getDayById(interval.endDay) ? t('general.daysShort.' + getDayById(interval.endDay)) : '';
                const dayString = startDay === endDay ? startDay : `${startDay}-${endDay}`;
                const timeString = `${interval.startTime}-${interval.endTime}`;
                const key = `${dayString} ${timeString}`;
                const value = interval.price ? formatPrice(interval.price) + tariff.priceElements[0].priceUnit : '-';
                return [key, value];
            })
        )),
    };

    return (
        <>
            {showTable ? (
                <>
                    <KeyValueTable className={className} title={objectData.name ?? t('object.data.text')} data={objectArr}
                               style={tableStyle}/>
                    <KeyValueTable className={className + ' mt-md'} title={t('object.chargingTariff')} data={tariffArr}
                               style={tableStyle}/>
                </>
            ) : (
                <>
                    <KeyValueText className={className} title={objectData.name ?? t('object.data.text')} data={objectArr}/>
                    <KeyValueText className={className} title={t('object.chargingTariff')} data={tariffArr}/>
                </>
            )}
        </>
    );
}

export default ObjectData;